import { FC, useState } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import Avatar from '../../assets/images/avatar.jpeg'

import styles from './sidebar.module.scss'

export const Sidebar: FC = () => {
  const [isActiveBurger, setActiveBurger] = useState(false)

  const isActiveStyles = ({ isActive }) => {
    return classNames(styles.nav_link, isActive ? styles.nav_link_active : null)
  }

  const handleToggleBurgerMenu = () => {
    setActiveBurger((value) => !value)
  }

  const handleCloseBurgerMenu = () => {
    setActiveBurger((value) => !value)
  }

  return (
    <>
      <aside className={classNames(styles.sidebar, isActiveBurger ? styles.sidebar_active : null)}>
        <div className={styles.img}>
          <img src={Avatar} alt="" />
          <p className={styles.img_text}>Анастасия Клемент</p>
        </div>
        <div>
          <nav className={styles.nav}>
            <NavLink onClick={handleCloseBurgerMenu} to="/" className={isActiveStyles}>Главная</NavLink>
            <NavLink onClick={handleCloseBurgerMenu} to="/portfolio" className={isActiveStyles}>Портфолио</NavLink>
            <NavLink onClick={handleCloseBurgerMenu} to="/about" className={isActiveStyles}>Обо мне</NavLink>
          </nav>
        </div>
      </aside>

      <div
        className={styles.burger}
        onClick={handleToggleBurgerMenu}
        >
        <div>
          <span className={classNames(isActiveBurger ? styles.burger_active : null)}></span>
        </div>
      </div>
    </>
  )
}