import { apiClient } from "./axios"

import { IWorksDataResponse } from "../models/works.model"

class WorksService {
  async getAll() {
    return apiClient.get<IWorksDataResponse>('/works?populate=*&sort[0]=createdAt:desc')
  }

  async getLast(limit: number = 6) {
    // TODO: вынести квери параметры
    return apiClient.get<IWorksDataResponse>(`/works?populate=*&pagination[limit]=${limit}&sort[0]=createdAt:desc`)
  }

  async getSnaps() {
    return apiClient.get<IWorksDataResponse>('/snaps?populate=*&sort[0]=createdAt:desc')
  }
}

const worksService = new WorksService()

export default worksService