import { BASE_URL } from "../api/axios"
import { IWork, IWorkResponse } from "../models/works.model"


export const normalizeWorksForLightbox = (array: IWorkResponse[]): IWork[] => {
  return array.map((elem) => ({
    url: `${BASE_URL}${elem.attributes.image.data.attributes.url}`,
    title: elem.attributes.title,
    type: elem.attributes.type,
  }))
}