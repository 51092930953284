import { FC } from 'react'
import { Link } from 'react-router-dom'

import Instagram from '../../assets/images/instagram.svg'
import Phone from '../../assets/images/phone.svg'
import Mail from '../../assets/images/mail.svg'
import { useContacts } from '../../hooks/useContacts'

import styles from './footer.module.scss'

export const Footer: FC = () => {
  const { data: contacts } = useContacts()

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <nav className={styles.nav}>
          <Link to="/" className={styles.nav_link}>Главная</Link>
          <Link to="/portfolio" className={styles.nav_link}>Портфолио</Link>
          <Link to="/about" className={styles.nav_link}>Обо мне</Link>
        </nav>
        <menu className={styles.menu}>
          <a href={contacts?.inst} className={styles.menu_social}>
            <img src={Instagram} alt="" />
          </a>
          <a href={`tel:${contacts?.phone}`} className={styles.menu_info}>
            <img src={Phone} alt="" />
            {contacts?.phone}
          </a>
          <a href={`mailto:${contacts?.email}`} className={styles.menu_info}>
            <img src={Mail} alt="" />
            {contacts?.email}
          </a>
        </menu>
      </div>
      <p className={styles.copyright}>Copyright ©2024 Все права защищены | Этот сайт создан <a href="https://vk.com/belovoloff_web" target='_blank'>mWhite_rr</a></p>
    </footer>
  )
}