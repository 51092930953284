import { useQuery } from "@tanstack/react-query"

import aboutService from "../api/about.service"

export const useAbout = () => {
  return useQuery({
    queryKey: ['about'],
    queryFn: () => aboutService.getAll(),
    select: ({ data }) => data.data.attributes
  })
}
