import Slider from "react-slick"

import styles from './slider.module.scss'

import Slider1 from '../../../../assets/images/slider-1.jpeg'
import Slider2 from '../../../../assets/images/slider-2.jpeg'
import Slider3 from '../../../../assets/images/about.jpeg'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var settings = {
  infinite: true,
  speed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  fade: true,
  autoplaySpeed: 3000,
}

export const SliderHome = () => {
  return (
    <Slider {...settings} className={styles.slider}>
      <div className={styles.slider_item}>
        <img src={Slider1} alt="" />
      </div>
      <div className={styles.slider_item}>
        <img src={Slider2} alt="" />
      </div>
      <div className={styles.slider_item}>
        <img src={Slider3} alt="" />
      </div>
    </Slider>
  )
}