import { useQuery } from "@tanstack/react-query"

import worksService from "../api/works.service"
import { normalizeWorksForLightbox } from "../utils"

export const useWorks = () => {
  return useQuery({
    queryKey: ['works'],
    queryFn: () => worksService.getAll(),
    select: ({ data }) => normalizeWorksForLightbox(data.data)
  })
}

export const useLastWorks = () => {
  return useQuery({
    queryKey: ['last-works'],
    queryFn: () => worksService.getLast(),
    select: ({ data }) => normalizeWorksForLightbox(data.data)
  })
}

export const useSnaps = () => {
  return useQuery({
    queryKey: ['snaps'],
    queryFn: () => worksService.getSnaps(),
    select: ({ data }) => normalizeWorksForLightbox(data.data)
  })
}