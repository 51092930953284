import { Route, Routes } from 'react-router-dom'

import { HomePage } from './pages/home'
import { AboutPage } from './pages/about'
import { PortfolioPage } from './pages/portfolio';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/portfolio" element={<PortfolioPage />} />
      <Route path="/portfolio/snapshot" element={<PortfolioPage snap />} />
      <Route path="/about" element={<AboutPage />} />
    </Routes>
  );
}

export default App;
