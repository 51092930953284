import { FC } from 'react'
import 'react-awesome-lightbox/build/style.css'

import { Layout } from '../../components/layout'
import { SliderHome } from './components/slider'
import { Footer } from '../../components/footer'
import { LastWorks } from '../../components/last-works'
import Instagram from '../../assets/images/instagram.svg'
import { useContacts } from '../../hooks/useContacts'

import styles from './home.module.scss'

export const HomePage: FC = () => {
  const { data: contacts } = useContacts()

  return (
    <Layout>
      <section className={styles.sec1}>
        <div className={styles.sec1_slider}>
          <SliderHome />
        </div>
        <div className={styles.sec1_info}>
          <span className={styles.sec1_info_span}>Привет! Я</span>
          <strong>Анастасия Клемент</strong>
          <h1>Анастасия Клемент <span>фотомодель, актриса</span></h1>
          <h2>Коммерческие и творческие проекты, реклама и  фотосессии.</h2>
          <menu className={styles.sec1_menu}>
            <a href={contacts?.inst} className={styles.sec1_menu_item}>
              <img src={Instagram} alt="" />
            </a>
          </menu>
        </div>
      </section>
      <LastWorks />
      <Footer />
    </Layout>
  )
}