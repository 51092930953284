import { FC, ReactNode } from 'react'

import { Sidebar } from '../sidebar'
import styles from './layout.module.scss'

interface IProps {
  children: ReactNode
}

export const Layout: FC<IProps> = ({ children }) => {
  return (
    <div className={styles.layout}>
      <Sidebar />
      <main className={styles.main}>
        {children}
      </main>
    </div>
  )
}