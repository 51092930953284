import { useState, FC } from "react"
import Lightbox from 'react-awesome-lightbox'

// TODO: алиасы
import { useLastWorks } from "../../hooks/useWorks"

import styles from './last-works.module.scss'


export const LastWorks: FC = () => {
  const [startIndex, setStartIndex] = useState<number | null>(null)
  const { data, error } = useLastWorks()

  console.log('data', data)

  //TODO: фикс
  if (!data) return null

  return (
    <section className={styles.sec2}>
      <h3>Последнии работы</h3>
      <div className={styles.container}>
        {data.map((item, index) => (
          <div onClick={() => setStartIndex(index)} className={styles.item} key={index}>
            <img src={item.url} alt={item.title} />
          </div>
        ))}
      </div>
      {startIndex !== null && (
        <Lightbox
          images={data}
          startIndex={startIndex}
          onClose={() => setStartIndex(null)}
        />
      )}
    </section>
  )
}