import { useQuery } from "@tanstack/react-query"

import contactsService from "../api/contacts.service"

export const useContacts = () => {
  return useQuery({
    queryKey: ['contacts'],
    queryFn: () => contactsService.getAll(),
    select: ({ data }) => data.data.attributes
  })
}
