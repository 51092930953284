import axios from "axios"

export const BASE_URL = process.env.REACT_APP_BASE_URL
const BASE_URL_API = `${BASE_URL}/api`

export const baseClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json"
  }
})

export const apiClient = axios.create({
  baseURL: BASE_URL_API,
  headers: {
    "Content-type": "application/json"
  }
})