import { FC } from 'react'

import { Footer } from "../../components/footer"
import { Layout } from "../../components/layout"
import { LastWorks } from '../../components/last-works'
import Instagram from '../../assets/images/instagram.svg'
import Phone from '../../assets/images/phone.svg'
import Mail from '../../assets/images/mail.svg'
import { useAbout } from '../../hooks/useAbout'
import { useContacts } from '../../hooks/useContacts'
import AboutImg from '../../assets/images/slider-3.jpeg'

import styles from './about.module.scss'

export const AboutPage: FC = () => {
  const { data } = useAbout()
  const { data: contacts } = useContacts()

  if (!data) return null

  return (
    <Layout>
      <section className={styles.sec1}>
        <div className={styles.sec1_photo}>
          <img src={AboutImg} alt="" />
        </div>
        <div className={styles.sec1_info}>
          <span className={styles.sec1_info_span}>Привет! Я</span>
          <strong>Анастасия Клемент</strong>
          <h1>Анастасия Клемент <span>фотомодель, актриса</span></h1>
          <h2>Коммерческие и творческие проекты, реклама и  фотосессии.</h2>
          <menu className={styles.sec1_menu}>
            <a href='/' className={styles.sec1_menu_item}>
              <img src={Instagram} alt="" />
            </a>
          </menu>
        </div>
      </section>
      <section className={styles.sec2}>
        <div className={styles.left}>
          <h3>Проекты и опыт</h3>
          <p>{data.description}</p>
          <ul className={styles.contacts}>
            <li>
              <a href={`tel:${contacts?.phone}`}>
                <img src={Phone} alt="" />
                {contacts?.phone}
              </a>
            </li>
            <li>
              <a href={`mailto:${contacts?.email}`}>
                <img src={Mail} alt="" />
                {contacts?.email}
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.right}>
          <p>
            РОСТ - <span>{data.height}</span>
            <br />
            ВЕС - <span>{data.weight}</span>
            <br />
            ГРУДЬ - <span>{data.bust}</span>
            <br />
            ТАЛИЯ - <span>{data.waist}</span>
            <br />
            БЕДРА - <span>{data.hips}</span>
            <br />
            РАЗМЕР ОДЕЖДЫ - <span>{data.dressSize}</span>
            <br />
            РАЗМЕР ОБУВИ - <span>{data.shoeSize}</span>
            <br />
            ЦВЕТ ГЛАЗ - <span>{data.colorEyes}</span>
            <br />
            ТИП ЛИЦА - <span>{data.faceType}</span>
            <br />
            ЦВЕТ ВОЛОС - <span>{data.hairColor}</span>
            <br />
            ДЛИНА ВОЛОС - <span>{data.hairSize}</span>
          </p>
        </div>
      </section>
      <LastWorks />
      <Footer />
    </Layout>
  )
}