import { apiClient } from "./axios"

import { IAboutDataResponse } from "../models/about.model"

class AboutService {
  async getAll() {
    return apiClient.get<IAboutDataResponse>('/about')
  }
}

const aboutService = new AboutService()

export default aboutService