import { apiClient } from "./axios"

import { IContactsDataResponse } from "../models/contacts.model"

class ContactsService {
  async getAll() {
    return apiClient.get<IContactsDataResponse>('/contact')
  }
}

const contactsService = new ContactsService()

export default contactsService