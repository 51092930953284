import { FC, useState } from "react"
import Lightbox from 'react-awesome-lightbox'
import { NavLink } from 'react-router-dom'

import { Layout } from '../../components/layout'
import { Footer } from '../../components/footer'
import { useWorks, useSnaps } from "../../hooks/useWorks"

import styles from './portfolio.module.scss'

interface IProps {
  snap?: boolean
}

export const PortfolioPage: FC<IProps> = ({ snap }) => {
  const useData = snap ? useSnaps : useWorks
  const [startIndex, setStartIndex] = useState<number | null>(null)
  const { data } = useData()

  if (!data) return null

  return (
    <Layout>
      <header className={styles.header}>
        <div className={styles.container}>
          <h1>Мои Работы</h1>
        </div>
      </header>
      {/* {!snap && (
        <section className={styles.snap}>
          <NavLink to="/portfolio/snapshot">
            <img src="https://kartinki.pics/uploads/posts/2022-03/1646875225_1-kartinkin-net-p-kartinki-dlya-dushi-so-smislom-1.jpg" alt="" />
            <h3>Снепы</h3>
          </NavLink>
        </section>
      )} */}
      <section className={styles.sec1}>
        <div className={styles.container}>
          {data.map((item, index) => (
            <div onClick={() => setStartIndex(index)} className={styles.item} key={index}>
              <img src={item.url} alt={item.title} />
              <div className={styles.item_info}>
                <p className={styles.item_text}>{item.title}</p>
                <p className={styles.item_type}>{item.type}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
      {startIndex !== null && (
        <Lightbox
          images={data}
          startIndex={startIndex}
          onClose={() => setStartIndex(null)}
        />
      )}
      <Footer />
    </Layout>
  )
}